import React from 'react';

import { Box } from '@core';
import { FeaturesTabs as FeaturesTabsContainer } from '@containers';

const FeaturesTabs = ({ title, description, padding }) => (
  <Box {...padding}>
    <FeaturesTabsContainer title={title} description={description} />
  </Box>
);

export default FeaturesTabs;
